iframe {
    width: 100%;
    height: 800px;
    border: none;
}

/*pretext {*/
/*    margin-right: 10%;*/
/*    margin-left: 10%;*/
/*    font-family: Tahoma;*/
/*}*/